<template>
  <b-card body-class="p-1rem m-0" class="mb-0 w-100 h-100">
    <div 
      class="max-height-area-options"
      v-if="!conversation_selected"
    >
      <div class="d-flex flex-wrap justify-content-between pb-2 mb-2 border-bottom">
        <h5 class="w-100">
          FormAI Options
          <feather-icon icon="InfoIcon" class="info-icons-textia" size="17" @click="openModalExtraInfo(true)"></feather-icon>
        </h5>
        <b-row class="w-100 m-0">
          <b-col class="col-8 p-0 mb-1">
            <component :is="vue_select" :options="networks" class="avenir-medium select-options-ai" v-model="options.network">
              <template #option="{ label }">
                <span class="avenir-medium">{{ label }}</span>
              </template>
            </component>
          </b-col>
          <b-col class="col-4 p-0 pl-1 mb-1">
            <b-form-input placeholder="Quantity" v-model="options.quantity"></b-form-input>
          </b-col>
          <b-col class="col-12 col-sm-7 p-0 mb-1">
            <component :is="vue_select" :options="$t('ia.text.persons')" class="avenir-medium select-options-ai" v-model="options.point_of_view">
              <template #option="{ label }">
                <span class="avenir-medium">{{ label }}</span>
              </template>
            </component>
          </b-col>
          <b-col class="col-12 col-sm-5 p-0 pl-sm-1">
            <component label="text" :is="vue_select" :options="$t('instaIdioma')" class="avenir-medium select-options-ai" v-model="options.language">
              <template #option="{ text }">
                <span class="avenir-medium">{{ text }}</span>
              </template>
            </component>
          </b-col>
        </b-row>
      </div>
      <div>
        <div class="d-flex justify-content-between mb-05">
          <h5 class="w-100">Writing Style 
            <feather-icon 
              icon="InfoIcon" 
              class="info-icons-textia" 
              size="17" 
              @click="openModalExtraInfo()" 
            ></feather-icon></h5>
          <div class="text-primary cursor-pointer" v-if="getOptionAllUsed() > 0" @click="resetValues()">{{ $t('reset') }}</div>
        </div>
        <div class="d-flex justify-content-between">
          <component 
            id="dropdown-prompts-built" 
            :is="vue_select"
            v-model="quick_prompts" 
            class="w-100 select-options-ai select-options-ai-setting dropdown-prompts-built" 
            label="title" 
            :options="$t('ia.text.writingStyles')"
          >
            <template #option="{title, subtitle, icon}">
              <div class="d-flex align-items-center">
                <div>
                  <b-img :src="require(`@/assets/images/svg/${icon}.svg`)" class="icons-dropdown-prompts-built"></b-img>
                </div>
                <div class="d-flex flex-column">
                  <span class="d-block">{{title}}</span>
                  <span class="avenir-medium white-space-pre-wrap">{{subtitle}}</span>
                </div>
              </div>
            </template>
            <template #selected-option="{title, icon}">
              <div>
                <b-img :src="require(`@/assets/images/svg/${icon}.svg`)" class="icons-dropdown-prompts-built"></b-img>
                {{ title }}
              </div>
            </template>
          </component> 
        </div>
        <div 
          class="mt-1 collapse-gray-area" 
          v-for="(section, index) in $t('ia.selects_text')" 
          :key="index"
        >
          <div
          @click="toggleCollapse(index)"
          class="d-flex justify-content-between align-items-center toggle-hover"
          >
            <div><span>{{section.text}}</span><span class="avenir-medium text-muted ml-02" v-if="getOptionUsed(index) > 0">({{ getOptionUsed(index) }})</span></div>
            <div class="container-plus-less-icon" :ref="`${index}-prompt`">
              <span></span>
              <span></span>
            </div>
          </div>
          <b-collapse :id="index" v-model="is_open[index]">
            <b-row class="mt-1">
              <div v-for="(options, index_child) in section.children_selects" :key="index_child" :class="section.class ? section.class : 'col-12 col-sm-6'">
                <component
                  :is="vue_select"
                  class="w-100 options-select-types select-options-ai mb-05" 
                  label="text"
                  :options="options"
                  :reduce="value => value.text"
                  :calculate-position="withPopper"
                  append-to-body
                  v-model="prompt_builder[index][index_child]"
                >
                  <template #header>
                    <div class="avenir-medium">{{ $t(`ia.text.${index_child}`) }}</div>
                  </template>
  
                  <template #option="{ text }">
                    <div class="avenir-medium">{{ text }}</div>
                  </template>
  
                  <template #selected-option="{ text }">
                    <div class="overflow-text">
                      {{text}}
                    </div>
                  </template>
                </component>
              </div>
            </b-row>
          </b-collapse>
        </div>
      </div>
    </div>
    <div v-else class="max-height-area-options">
      <h5 class="w-100">{{ $t('ia.text.sessionProperties')}}</h5>
      <p class="avenir-medium border-bottom pb-1">{{ formatDateIA(new Date(conversation_selected.created_at)) }}</p>

      <div class="d-flex justify-content-between mb-1">
        <div>
          <span class="d-block avenir-medium">PROMPT</span>
          <span class="d-block">{{ conversation_selected.messages[0].original_prompt }}</span>
        </div>
       
        <div> 
          <b-dropdown class="avenir-medium dropdown-options-sidebar-history" variant="link" no-caret>
            <template #button-content>
              <feather-icon icon="MoreHorizontalIcon"></feather-icon>
            </template>
            <b-dropdown-item @click="editHistory()">{{ $t('lists.edit') }} prompt</b-dropdown-item>
            <b-dropdown-item @click="copyAction(conversation_selected.messages[0].original_prompt)">{{ $t('ia.chat.copy') }} prompt</b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item @click="collapse_full_prompt = !collapse_full_prompt">{{collapse_full_prompt ? $t('ia.history.hide') : $t('ia.history.view')}} {{ $t('ia.history.viewFullPrompt') }}</b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
      <div class="row">
        <b-collapse v-model="collapse_full_prompt" class="col-12">
          <span class="d-block avenir-medium text-uppercase">{{ $t('ia.history.viewFullPrompt') }}</span>
          <span class="d-block">{{ conversation_selected.messages[0].content }}</span>
        </b-collapse>
      </div>

      <div class="row" :class="{'mt-1': collapse_full_prompt}">
        <div class="col-6 mb-1">
          <span class="d-block avenir-medium text-uppercase">{{ $t('invitation.Network') }}</span>
          <span class="d-block">{{conversation_selected.data.network}}</span>
        </div>
        <div class="col-6 mb-1">
          <span class="d-block avenir-medium text-uppercase">{{ $t('youtube.idiom') }}</span>
          <span class="d-block">{{$t('instaIdioma').find(i => i.value === conversation_selected.data.language).text}}</span>
        </div>

        <div class="col-6 mb-1">
          <span class="d-block avenir-medium text-uppercase">{{ $t('ia.text.pointView') }}</span>
          <span class="d-block">{{conversation_selected.data.point_of_view}}</span>
        </div>
      </div>

      <!-- OPTIONS TEXT IA -->
      <div>
        <div 
          v-for="(section, index) in prompt_builder" 
          :key="index"
        >
          <div v-if="!Object.values(section).every(i => i === null)" class="mt-1 collapse-gray-area">
            <div
              @click="toggleCollapse(index)"
              class="d-flex justify-content-between align-items-center toggle-hover"
            >
              <div><span>{{ $t('ia.selects_text')[index].text }}</span></div>
              <div class="container-plus-less-icon" :ref="`${index}-prompt`">
                <span></span>
                <span></span>
              </div>
            </div>
            <b-collapse :id="index" v-model="is_open[index]">
              <div class="mt-1 w-100 d-flex flex-wrap">
                <div v-for="(options, inner_index) in section" :key="inner_index" :class="`p-0 w-100 ${!options ? 'd-none' : 'col-6'}`" >
                    <div class="mb-1 w-100">
                      <span class="d-block avenir-medium text-uppercase">{{ $t(`ia.text.${inner_index}`) }}</span>
                      <span class="d-block">{{ options }}</span>
                    </div>
                </div>
              </div>
            </b-collapse>
          </div>
        </div>
      </div>
    </div>
    <template #footer v-if="conversation_selected">
      <div class="d-flex justify-content-end">
        <b-button variant="blue-button" class="blue-button" @click="editHistory()">{{ $t('ia.history.view/edit') }}</b-button>
      </div>   
    </template>

    <b-sidebar v-model="extra_info_sidebar.model" backdrop shadow right class="sidebar-extra-info-options-ia" v-if="!conversation_selected">
      <template #header>
        <div class="p-1 border-bottom d-flex w-100">
          <h4 v-if="!extra_info_sidebar.is_writing_style">{{ $t('search.textOptions').find(i => i.value === get_text_type).text }}: FormAI Options</h4>
          <h4 v-else>Learn More: Writing Style</h4>

          <feather-icon icon="XIcon" class="x-icon-sidebar-info" @click="extra_info_sidebar.model = false"></feather-icon>
        </div>
      </template>
      <div v-html="extra_info_sidebar.text" class="px-2 py-1 avenir-medium"></div>
    </b-sidebar>
  </b-card>
</template>

<script>
import { 
  BRow,
  BCol,
  BCard,
  BImg,
  BFormInput,
  BCollapse,
  BDropdown,
  BDropdownItem,
  BButton,
  BSidebar,
  BDropdownDivider,
} from 'bootstrap-vue';
import { createPopper } from '@popperjs/core';
import { formatDateIA } from '@/libs/utils/times';
const loadToastificationContent = () => import('@core/components/toastification/ToastificationContent.vue');

export default {
  name: 'siderRightTextOptions',
  components: {
    BRow,
    BCol,
    BCard,
    BImg,
    BFormInput,
    BCollapse,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BButton,
    BSidebar,
  },
  props: {
    options: {
      type: Object,
    },
    conversation_selected: {
      type: Object,
    },
    prompt_builder: {
      type: Object,
    }
  },
  data() {
    return {
      vue_select: null,
      networks: ['Instagram', 'TikTok', 'Threads', 'Youtube', 'Twitter', 'Facebook', 'Twitch', 'Blog', 'Custom'],
      placement: 'top',
      is_open: {},
      collapse_full_prompt: false,
      quick_prompts: this.$t('ia.text.writingStyles')[0],
      extra_info_sidebar: {
        model: false,
        text: '',
        is_writing_style: false,
      },
      formatDateIA,
    }
  },
  computed: {
    get_text_type() {
      return this.$route.params.category.replace('-', '_')
    }
  },
  async mounted() {
    this.vue_select = (await import('vue-select')).default;
  },
  created() {
  },
  methods: {
    openModalExtraInfo(is_writing_style = false) { 
      this.extra_info_sidebar.model = true;
      this.extra_info_sidebar.is_writing_style = is_writing_style;
      this.extra_info_sidebar.text = this.$t(`ia.text.sidebars.${is_writing_style ? this.get_text_type : 'writingStyle'}`)
    },
    editHistory() {
      const category = this.conversation_selected.type_text.replace('_', '-')
      this.$router.push({ name: 'brandme_AI', params: { section: 'text', category, uuid: this.conversation_selected.uuid}});
      this.$emit('back_history', 'text')
    },
    copyAction(link) {
      this.$copyText(link).then(() => {
        loadToastificationContent().then((module) => {
          this.$toast({
            component: module.default,
            props: {
              title: this.$t('campaigns.copied'),
              icon: "CopyIcon",
              text: this.$t('ia.general.promptCopied'),
              variant: 'success'
            },
          });
        })
      });
    },
    withPopper(dropdownList, component, { width }) {
      dropdownList.style.width = width
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: this.placement,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1],
            },
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({ state }) {
              component.$el.classList.toggle(
                'drop-up',
                state.placement === 'top'
              )
            },
          },
        ],
      })
      return () => popper.destroy()
    },
    getOptionUsed(section) {
      return Object.values(this.prompt_builder[section]).filter(model => model).length;
    },
    toggleCollapse(section) {
      this.is_open[section] = !this.is_open[section]
      const icon_plus_promp = this.$refs[`${section}-prompt`];
      icon_plus_promp[0].classList.toggle("transition-icon-plus");
      icon_plus_promp[0].classList.toggle("transition-form");
    },
    getRandomOptions() {
      
      const total_sections_label = Object.keys(this.$t('ia.selects_text'))
      total_sections_label.sort(() => 0.3 - Math.random());
      const sections_sliced = total_sections_label.slice(0, Math.floor(Math.random() * total_sections_label.length) + 1)
      
      const random_values = {...this.prompt_builder}
      sections_sliced.forEach((section) => {
        random_values[section] = {};
        const section_object = this.$t('ia.selects_text')[section];
        const total_selects_label = Object.keys(section_object.children_selects);

        total_selects_label.sort(() => 0.3 - Math.random());
        const selects_label_sliced = total_selects_label.slice(0, Math.floor(Math.random() * total_selects_label.length) + 1)

        selects_label_sliced.forEach(element => {
          const array = this.$t('ia.selects_text')[section].children_selects[element];
          array.sort(() => 0.3 - Math.random());
          const value_selected = array[Math.floor(Math.random() * array.length)]
          random_values[section][element] = value_selected.text
        });

      })
      return random_values
    },
    resetValues() {
      this.$emit('update_prompt', {})
      this.quick_prompts = this.$t('ia.text.writingStyles')[0];
    },
    getOptionAllUsed() {
      return Object.values(this.prompt_builder).flatMap(section => Object.values(section)).filter(model => model).length;
    },
  },
  watch: {
    quick_prompts(val) {
      if (val.key === 'surprise') {
        this.$emit('update_prompt', this.getRandomOptions())
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.info-icons-textia {
  margin-left: 0.2em;
  color: #afaeae;
  cursor: pointer;
}
.dropdown-prompts-built {
  .icons-dropdown-prompts-built {
    height: 35px;
    width: 35px;
    border: 3px solid rgb(228, 228, 228);
    border-radius: 100%;
    margin-right: 0.5em;
  }
}
.x-icon-sidebar-info {
  position: absolute;
  top: 1em;
  right: 1em;
  height: 18px;
  width: 18px;
  cursor: pointer;
}
.ml-02 {
  margin-left: 0.5em;
}
.mb-05 {
  margin-bottom: 0.5em;
}

.toggle-hover:hover{
  cursor: pointer;
}
.max-height-area-options{
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;

  .dropdown-options-sidebar-history {
    margin-top: -0.8em !important;
  }
}
.collapse-gray-area {
  background: #F8F9FA;
  padding: 1rem ;
  border-radius: 0.7em;
}
.container-plus-less-icon {
  background: transparent;
  width: 30px;
  height: 30px;
  border: 0;
  position: relative;

  span {
    position: absolute;
    transition: 300ms;
    background: #999999;

    &:first-child {
      top: 25%;
      bottom: 25%;
      width: 10%;
      left: 45%;
    }
    &:last-child {
      left: 25%;
      right: 25%;
      height: 10%;
      top: 45%;
    }
  }
}
.transition-icon-plus {
  span {
    transform: rotate(90deg);

    &:last-child {
      left: 50%;
      right: 50%;
    }
  }
}
</style>

<style lang="scss">
.dropdown-prompts-built .vs__dropdown-toggle {
  height: 46.5px !important;
}
.vs__dropdown-menu {
  z-index: 10000;
}
.sidebar-extra-info-options-ia .b-sidebar {
  width: 400px
}
.select-options-ai {
  .vs__selected {
    font-family: 'avenir-medium';
  }
  .vs__clear { 
    display: none;
  }
}
</style>